<template>
    <div class="tabs">
        <div class="topSearch">
            站点名称 <el-input class="selectBox" size="small" v-model="pageParams.stationName" placeholder="请输入"></el-input>
            站点类型 <el-select class="selectBox" size="small" v-model="pageParams.stationSecondType" placeholder="请选择">
                <el-option v-for="item in stationTypeDict" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
            </el-select>
            <!-- 管理单位 <el-select class="selectBox" size="small" placeholder="请选择"></el-select> -->
            <div class="searchBtn">
                <el-button type="primary" size="small" @click="search">查询</el-button>
                <el-button type="info" plain size="small" @click="reset">重置</el-button>
            </div>
        </div>
        <div class="operateBtn">
            <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="add()">添加</el-button>
            <el-button type="primary" size="small" plain @click="exportDefault">导出</el-button>
        </div>
        <el-table :data="tableData" border class="tableStyle" height="calc(100vh - 351px)">
            <el-table-column label="序号" type="index" width="52">
            </el-table-column>
            <el-table-column v-for="col in head" :key="col.prop" :prop="col.prop" :label="col.label" align="left"
                show-overflow-tooltip :sortable="col.sorts" :width="col.widths">
                <template v-if="col.isBar" #default="{ row }">
                    <div v-if="col.prop == 'gisStationId'" class="linkBtn">
                        <span v-if="row.gisStationId" class="linkBtn" @click="gisPositioning(row)">GIS定位</span>
                        <span v-else class="linkBtn" @click="associatedGIS(row)">关联GIS</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
                <template slot-scope="{ row }">
                    <span class="linkBtn" style="margin-right:16px" @click="add(row.id)">编辑</span>
                    <span class="linkBtn" style="margin-right:16px" @click="del(row.id)">删除</span>
                    <span class="linkBtn" @click="monitor(row.id)">监测点位</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageParams.current" :page-sizes="[30, 50, 100]" :page-size="pageParams.size"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <add ref="add"></add>
        <associatedGIS ref="associatedGIS"></associatedGIS>
        <location ref="location"></location>
        <monitoring ref="monitoring"></monitoring>
        <addMonitorPoint ref="addMonitorPoint"></addMonitorPoint>
        <bind ref="bind"></bind>
    </div>
</template>

<script>
import gasStationApi from '@/apis/siteAdministration/gasStationApi'
import { exportMethod } from '@/common/js/exportExcel'
import indexMixin from '@/mixins/index.js'
import { mapActions, mapState } from 'vuex'
import add from './components/add.vue'
import addMonitorPoint from './components/addMonitorPoint.vue'
import associatedGIS from './components/associatedGIS.vue'
import bind from './components/bind.vue'
import location from './components/location.vue'
import monitoring from './components/monitoring.vue'
export default {
    name: 'userStationMainten',
    components: { add, associatedGIS, location, monitoring, addMonitorPoint, bind },
    mixins: [indexMixin],
    data() {
        return {
            pageParams: {
                size: 100,
                current: 1
            },
            surePageParams: {},
            tableData: [],
            head: [
                { prop: 'stationName', label: '站点名称' },
                { prop: 'stationSecondTypeName', label: '站点类型' },
                { prop: 'gasTypeName', label: '燃气介质', widths: '100' },
                { prop: 'ownershipCompany', label: '权属单位' },
                { prop: 'buildDate', label: '建设日期' },
                { prop: 'designScale', label: '设计规模(Nm³/h)' },
                { prop: 'positionDes', label: '位置描述' },
                { prop: 'usingStatusName', label: '使用状态', widths: '100' },
                { prop: 'gisStationId', label: 'GIS位置', isBar: true },
                { prop: 'remark', label: '备注' },
                { prop: 'importantName', label: '重点检测' }
            ],
            total: 0
        }
    },
    computed: {
        ...mapState('dict', ['stationType'])
    },
    async created() {
        //    let res =  await scadaApi.getOptionsKyes()
        //    console.log(res);
    },
    async mounted() {
        await this.getDropdown('stationType')
        this.isStringStartsWith1Or2('用户站')
        this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
        this.getList(this.pageParams)
    },
    methods: {
        ...mapActions('dict', ['getDropdown']),
        /**
         * Description 删除
         * @author slx
         * @date 2024-01-03
         * @param {any} id
         * @returns {any}
         */
        del(id) {
            this.$confirm('确认删除该站点信息及其监测点位信息？', '提示', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                gasStationApi.deleteStation({ id }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.search()
                    } else this.$message.error(res.msg)
                })
            }).catch(action => { });
        },
        /**
         * Description  监测点位
         * @author slx
         * @date 2024-01-05
         * @param {any} id
         * @returns {any}
         */
        monitor(id) {
            this.$refs.monitoring.open(id, '用户站')
        },
        search() {
            // this.pageParams.current = 1
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams)
        },
        reset() {
            this.pageParams = {
                current: 1,
                size: 100
            }
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams)
        },
        add(id) {
            this.$refs.add.open(id, '用户站')
        },
        handleSizeChange(e) {
            this.pageParams.size = e;
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams);
        },
        handleCurrentChange(e) {
            this.pageParams.current = e;
            this.surePageParams = JSON.parse(JSON.stringify(this.pageParams))
            this.getList(this.pageParams);
        },
        gisPositioning(row) {
            let { gisStationId, gisStationLocation } = row
            this.$refs.location.open(row, gisStationLocation)
        },
        associatedGIS(row) {
            let { id, stationName } = row
            this.$refs.associatedGIS.open({ scadaStationId: id, stationName }, '用户站')
        },
        async getList(pageParams) {
            let params = JSON.parse(JSON.stringify(pageParams))
            let { value } = this.stationType.find(item => { return item.label.includes('用户站') })
            params.stationType = value || 2
            gasStationApi.getList(params).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.list
                    this.total = res.data.total
                    if (res.data.total && !res.data.list.length) {
                        params.current = Math.ceil(res.data.total / params.size)
                        this.surePageParams = JSON.parse(JSON.stringify(params))
                        this.getList(params)
                    }
                } else this.$message.error(res.msg)
            })
        },
        exportDefault() {
            let params = JSON.parse(JSON.stringify(this.surePageParams))
            let { value } = this.stationType.find(item => { return item.label.includes('用户站') })
            params.stationType = value || 2
            params.current = 0
            params.size = -1
            gasStationApi.exportList(params).then(res => {
                exportMethod(res, '用户站')
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.linkBtn {
    cursor: pointer;
    color: #1082FF;
}

.tabs {
    margin: 10px;
    background: #fff;
    padding: 16px 10px;
    height: calc(100vh - 205px);

    .topSearch {
        color: #0F0F0F;
        border-bottom: 1px solid #E3E3E3;
        padding: 0 10px 16px;

        .selectBox {
            width: 200px;
            margin: 0 30px 0 4px;
        }

        .searchBtn {
            display: inline-block;
        }
    }

    .operateBtn {
        margin: 16px 0 10px;
    }
}
</style>